import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  // eslint-disable-next-line no-return-await
  get: async (url, params = {}) => {
    const user = JSON.parse(localStorage.getItem('token'))
    const options = {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
    }
    // Start
    let dataRes = null
    if (!params.site_id) {
      // eslint-disable-next-line no-param-reassign
      params.site_id = JSON.parse(localStorage.getItem('siteID')).id
    }
    let strParams = ''
    Object.keys(params).map(isKey => {
      if (params[isKey]) {
        strParams += `&${isKey}=${params[isKey]}`
      }
      return true
    })
    const urlApi = `${process.env.VUE_APP_API_AFFILIATE}${url}?v=1${strParams}`
    const res = await axios.get(urlApi, options)
    if (res.status === 200) {
      dataRes = res.data
    }
    return dataRes
  },
  getOriginal: async (url, params = {}) => {
    const user = JSON.parse(localStorage.getItem('token'))
    const options = {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
    }
    // Start
    let dataRes = null
    if (!params.site_id) {
      // eslint-disable-next-line no-param-reassign
      params.site_id = JSON.parse(localStorage.getItem('siteID')).id
    }
    let strParams = ''
    Object.keys(params).map(isKey => {
      if (params[isKey]) {
        strParams += `&${isKey}=${params[isKey]}`
      }
      return true
    })
    const urlApi = `${process.env.VUE_APP_API_AFFILIATE_ORIGINAL}${url}?v=1${strParams}`
    const res = await axios.get(urlApi, options)
    if (res.status === 200) {
      dataRes = res.data
    }
    return dataRes
  },
  post: async (url, params = {}) => {
    // Auth
    const user = JSON.parse(localStorage.getItem('token'))
    const options = {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
    }
    // Start
    let dataRes = null
    if (!params.site_id) {
      // eslint-disable-next-line no-param-reassign
      params.site_id = JSON.parse(localStorage.getItem('siteID')).id
    }
    // let strParams = ''
    // Object.keys(params).map(isKey => {
    //   if (params[isKey]) {
    //     strParams += `&${isKey}=${params[isKey]}`
    //   }
    //   return true
    // })
    try {
      const res = await axios.post(`${process.env.VUE_APP_API_AFFILIATE}${url}`, params, options)
      if (res.status === 200) {
        dataRes = res.data
      }
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Opps! Something wrong',
          icon: 'AlertOctagonIcon',
          variant: 'danger',
          text: String(error),
        },
      })
    }
    return dataRes
  },
  put: async (url, params = {}) => {
    // Auth
    const user = JSON.parse(localStorage.getItem('token'))
    const options = {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
    }
    // Start
    let dataRes = null
    if (!params.site_id) {
      // eslint-disable-next-line no-param-reassign
      params.site_id = JSON.parse(localStorage.getItem('siteID')).id
    }
    try {
      const res = await axios.put(`${process.env.VUE_APP_API_AFFILIATE}${url}`, params, options)
      if (res.status === 200) {
        dataRes = res.data
      }
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Opps! Something wrong',
          icon: 'AlertOctagonIcon',
          variant: 'danger',
          text: String(error),
        },
      })
    }
    return dataRes
  },
  delete: async (url, params = {}) => {
    // Auth
    const user = JSON.parse(localStorage.getItem('token'))
    const options = {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
    }
    // Start
    let dataRes = null
    if (!params.site_id) {
      // eslint-disable-next-line no-param-reassign
      params.site_id = JSON.parse(localStorage.getItem('siteID')).id
    }
    let strParams = ''
    Object.keys(params).map(isKey => {
      if (params[isKey]) {
        strParams += `&${isKey}=${params[isKey]}`
      }
      return true
    })
    try {
      const res = await axios.delete(`${process.env.VUE_APP_API_AFFILIATE}${url}?v=1${strParams}`, options)
      if (res.status === 200) {
        dataRes = res.data
      }
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Opps! Something wrong',
          icon: 'AlertOctagonIcon',
          variant: 'danger',
          text: String(error),
        },
      })
    }
    return dataRes
  },
  unitFormatOriginal(value, local = 'vi-VN') {
    return `${new Intl.NumberFormat(local, { style: 'currency', currency: 'VND' }).format(value)}`
  },
  dateFormat(date) {
    // if (!date) return
    const dateArr = date.split('-')
    const year = dateArr[0]
    const month = dateArr[1]
    const day = dateArr[2]
    const result = (day, '/', month, '/', year).join()
    return result
  },
  methods: {
    async mixGetAffi(url = '', params = {}) {
      // Auth
      const user = JSON.parse(localStorage.getItem('token'))
      const options = {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      }
      // Start
      let dataRes = null
      if (!params.site_id) {
        // eslint-disable-next-line no-param-reassign
        params.site_id = JSON.parse(localStorage.getItem('siteID')).id
      }
      let strParams = ''
      Object.keys(params).map(isKey => {
        if (params[isKey] !== undefined) {
          strParams += `&${isKey}=${params[isKey]}`
        }
        return true
      })
      const urlApi = `${process.env.VUE_APP_API_AFFILIATE}${url}?v=1${strParams}`
      const res = await axios.get(urlApi, options)
      if (res.status === 200) {
        dataRes = res.data
      }
      return dataRes
    },

    async mixPostAffi(url = '', params = {}) {
      // Auth
      const user = JSON.parse(localStorage.getItem('token'))
      const options = {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      }
      // Start
      let dataRes = null
      if (!params.site_id) {
        // eslint-disable-next-line no-param-reassign
        params.site_id = JSON.parse(localStorage.getItem('siteID')).id
      }
      try {
        const res = await axios.post(`${process.env.VUE_APP_API_AFFILIATE}${url}`, params, options)
        if (res.status === 200) {
          dataRes = res.data
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return dataRes
    },

    async mixPutAffi(url = '', params = {}) {
      // Auth
      const user = JSON.parse(localStorage.getItem('token'))
      const options = {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      }
      // Start
      let dataRes = null
      if (!params.site_id) {
        // eslint-disable-next-line no-param-reassign
        params.site_id = JSON.parse(localStorage.getItem('siteID')).id
      }
      try {
        const res = axios.put(`${process.env.VUE_APP_API_AFFILIATE}/${url}`, params, options)
        if (res.status === 200) {
          dataRes = res.data
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return dataRes
    },
    dateFormatTime(date) {
      const today = new Date(date)
      const yyyy = today.getFullYear()
      let mm = today.getMonth() + 1
      let dd = today.getDate()

      if (dd < 10) dd = `0${dd}`
      if (mm < 10) mm = `0${mm}`

      const h = today.getHours() < 10 ? `0${today.getHours()}` : today.getHours()
      const m = today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes()
      const s = today.getSeconds() < 10 ? `0${today.getSeconds()}` : today.getSeconds()
      const time = `${h}:${m}:${s}`

      const res = `${time} ${dd}/${mm}/${yyyy}`
      return res
    },
    async mixFormDataAffi(url = '', params = {}) {
      // Auth
      const user = JSON.parse(localStorage.getItem('token'))
      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user.access_token}`,
        },
      }

      let dataRes = null
      const formData = new FormData()
      Object.keys(params).map(keyParam => {
        formData.append(keyParam, params[keyParam])
        return true
      })

      try {
        const res = await axios.post(`${process.env.VUE_APP_API_AFFILIATE}${url}`, formData, options)
        if (res.status === 200) {
          dataRes = res.data
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return dataRes
    },
  },
}
