<template>
  <section class="affiliate-layout">
    <aside
      ref="affiliate-nav"
      class="affiliate-nav rs-scrollbar"
    >
      <router-link
        v-for="tab in navTabs"
        :key="tab.id"
        :ref="`${tab.id}`"
        :class="['affiliate-nav__item', { 'active': tab.path === $route.name }]"
        :to="{ name: tab.path || 'dashboard-homepage-v1' }"
      >
        <feather-icon
          :icon="tab.icon"
          size="32"
        />
        {{ tab.label }}
      </router-link>
    </aside>

    <slot />
  </section>
</template>

<script>
export default {
  props: {},

  data() {
    return {
      navActive: '',
      navTabs: [
        {
          id: 'general',
          icon: 'HomeIcon',
          label: 'Tổng quan',
          path: 'affiliate-general',
        },
        // {
        //   id: 'membership',
        //   icon: 'ActivityIcon',
        //   label: 'Membership program',
        //   path: '',
        // },
        {
          id: 'commission',
          icon: 'SlackIcon',
          label: 'Commission setting',
          path: 'affiliate-commission-category',
        },
        {
          id: 'order',
          icon: 'ShoppingCartIcon',
          label: 'Đơn hàng',
          path: 'affiliate-order-list',
        },
        {
          id: 'report',
          icon: 'ClipboardIcon',
          label: 'Báo cáo',
          path: 'affiliate-report',
        },
        {
          id: 'payment',
          icon: 'DollarSignIcon',
          label: 'Thanh toán',
          path: 'affiliate-payment-list',
        },
        {
          id: 'sellers',
          icon: 'UsersIcon',
          label: 'Sellers',
          path: 'affiliate-member-list',
        },
        {
          id: 'rank-segment',
          icon: 'BarChartIcon',
          label: 'Rank segment',
          path: 'affiliate-rank-segment',
        },
        {
          id: 'referrals',
          icon: 'UsersIcon',
          label: 'Referrals',
          path: 'affiliate-referrals-add',
        },
      ],
    }
  },

  mounted() {
    this.navActive = this.$router.currentRoute.meta.navActiveLink
    this.moveToActiveTab()
  },

  methods: {
    moveToActiveTab() {
      const affiliateNav = this.$refs['affiliate-nav']
      const affiliateNavItems = affiliateNav.childNodes

      affiliateNavItems.forEach(item => {
        const isActive = item.classList.contains('router-link-exact-active') || item.classList.contains('active')
        let posLeft = 0

        if (isActive) {
          posLeft = item.offsetLeft
          affiliateNav.scrollTo({
            left: posLeft,
            behavior: 'smooth',
          })
        }
        return false
      })
    },
  },
}
</script>

<style lang="sass">
.affiliate-nav
  display: grid
  grid-auto-columns: minmax(137px, 1fr)
  grid-auto-flow: column
  border: 1px solid rgba(62, 68, 75, 0.05)
  overflow-x: auto
  margin-bottom: 1.5rem

  &__item
    min-height: 108px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding: 0.5rem
    gap: 0.5rem
    text-align: center
    color: var(--gray)
    transition: all .25s
    border: 1px solid rgba(62, 68, 75, 0.05)

    &.active,
    &.router-link-exact-active
      color: #FFFFFF
      background: var(--primary)
</style>
