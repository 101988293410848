<template>
  <AffiliateLayout>
    <b-card
      class="member-block"
      no-body
      title="Thiết lập list memeber"
    >
      <div class="px-2">
        <div class="filter-tab w-100">
          <div
            v-for="item in listTileTab"
            :key="item.id"
            :class="['filter-tab__item', {'active': activeTab == item.id}]"
            @click="activeTab = item.id"
          >
            <span>{{ $t(item.name) }}</span>
            <span v-if="obCountStatus[`status${item.id}`]">({{ obCountStatus[`status${item.id}`].count }})</span>
          </div>
        </div>
      </div>

      <div class="p-2 list-btn-block justify-content-end">
        <!-- <div
          v-b-modal.modal-1
          class="btn"
        >
          Tạo nhóm
        </div>
        <div class="btn">
          Duyệt tất cả
        </div> -->
        <div class="d-flex align-items-center w-50">
          <b-input-group>
            <b-form-input
              v-model="filterData"
              placeholder="Nhập mã đơn..."
            />
            <b-input-group-append>
              <b-button variant="outline-primary">
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-form-group class="w-50">
            <validation-provider
              #default="{ errors }"
              rules="required"
            >
              <v-select
                v-model="filterSelected"
                :options="filterList"
                label="label"
                placeholder="Bộ lọc"
                class="ml-2"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>

      <b-card-body class="p-0">
        <vue-good-table
          ref="promotion-products"
          class="custom-good-table custom-good-table-ctrl-border commission"
          :columns="fields"
          :rows="listItems"
          :line-numbers="true"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span>
              {{ $t(props.column.label) }}
            </span>
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: percent_discount -->
            <span v-if="props.column.field === 'id_user'">
              <router-link :to="`edit/${props.row.id}`">
                {{ props.row.idUser }}
              </router-link>
            </span>

            <!-- Column: fixed_costs -->
            <span v-else-if="props.column.field === 'name'">
              {{ props.row.name }}
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'phone'">
              {{ props.row.phone }}
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'email'">
              {{ props.row.email }}
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <div class="text-danger d-flex flex-wrap align-items-center justify-content-center">
                <b-button
                  variant="flat-dark"
                  class="btn-icon rounded-circle"
                  @click="onShowDetail(props.row.id)"
                >
                  <feather-icon icon="Edit3Icon" />
                </b-button>
                <div v-if="props.row.is_active == 0">
                  <b-badge
                    variant="light-dark"
                  >
                    Chờ duyệt
                  </b-badge>
                </div>
                <div v-else-if="props.row.is_active == 1">
                  <b-badge
                    variant="light-success"
                  >
                    Đang hoạt động
                  </b-badge>
                </div>
                <div v-else-if="props.row.is_active == 2">
                  <b-badge
                    variant="light-warning"
                  >
                    Tạm dừng
                  </b-badge>
                </div>
                <div v-else-if="props.row.is_active == 3">
                  <b-badge
                    variant="light-danger"
                  >
                    Dừng
                  </b-badge>
                </div>
              </div>
            </span>

          </template>
          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap ctrl-pd-1-5 p-2">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['5','10','20']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-body>

      <!-- tao nhóm modal -->
      <b-modal
        id="modal-1"
        title="Tạo nhóm"
        ok-only
        ok-title="Tạo nhóm"
        no-close-on-backdrop
      >
        <b-card-text>
          <div class="w-100">
            <b-form-group
              :label="`*` + $t('Tên nhóm') + `:`"
              label-cols-md="12"
            >
              <validation-provider
                #default="{ errors }"
                name="Tên nhóm"
              >
                <b-form-input
                  v-model="nameGroup"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Tên nhóm')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              :label="`*${$t('Thời gian')}`"
              label-cols-md="12"
              class="inline-time-group"
            >
              <flat-pickr
                v-model="model.use_date"
                class="form-control x-input-time"
                :config="{
                  minDate: 'today',
                  dateFormat: 'Y-m-d',
                }"
                placeholder="Thời gian:"
              />
            </b-form-group>
            <b-form-group
              :label="`*` + $t('Địa chỉ') + `:`"
              label-cols-md="12"
            >
              <validation-provider
                #default="{ errors }"
                name="Địa chỉ"
              >
                <b-form-input
                  v-model="nameGroup"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Địa chỉ')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="`*${$t('Giới tính')}`"
              label-cols-md="12"
              class="inline-time-group"
            >
              <div class="ctrl-radio">
                <b-form-radio
                  v-model="SelectedGT"
                  name="some-radios"
                  value="A"
                >
                  Nam
                </b-form-radio>
                <b-form-radio
                  v-model="SelectedGT"
                  name="some-radios"
                  value="B"
                >
                  Nữ
                </b-form-radio>
              </div>
            </b-form-group>
            <b-form-group
              :label="`*${$t('Hạng')}`"
              label-cols-md="12"
              class="inline-time-group"
            >
              <b-form-group class="w-100">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <v-select
                    v-model="model.selectedHang"
                    :options="optionsHang"
                    label="name"
                    placeholder="Bộ lọc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-form-group>
            <b-form-group
              :label="`*${$t('Thêm thuộc tính khác')}`"
              label-cols-md="12"
              class="inline-time-group"
            >
              <b-form-group class="w-100">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <v-select
                    v-model="model.selectedKhac"
                    :options="optionsKhac"
                    label="name"
                    placeholder="Bộ lọc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-form-group>
          </div>
        </b-card-text>
      </b-modal>

      <!-- member information modal -->
      <b-modal
        v-model="memberInfoShow"
        title="Chi tiết CTV"
        ok-only
        no-close-on-backdrop
        hide-footer
        no-stacking
        header-text-variant="light"
        header-bg-variant="primary"
        header-close-variant="danger"
      >
        <b-card-text
          v-if="memberInfo"
        >
          <div class="w-100 modal-profile">
            <b-form-group>
              <div class="img-avata">
                <img
                  :src="memberInfo.customer[0].avatar || '/logo.png'"
                  :alt="memberInfo.customer[0].fullname"
                >
              </div>
            </b-form-group>
            <b-form-group>
              <div class="title-big-modal">
                Thông tin chung
              </div>
            </b-form-group>
            <div class="one-item">
              <div class="title-info">
                Họ và tên CTV
              </div>
              <div class="content-info">
                {{ memberInfo.customer[0].fullname }}
              </div>
            </div>
            <div
              class="d-flex flex-wrap prf-col"
              style="margin: 0 -0.5rem"
            >
              <div
                class="one-item w-50"
                style="padding: 0 0.5rem"
              >
                <div class="title-info">
                  Ngày/ tháng/ năm sinh
                </div>
                <div class="content-info">
                  {{ dateFormat(memberInfo.customer[0].birthday) || 'Không xác định' }}
                </div>
              </div>
              <div
                class="one-item w-50"
                style="padding: 0 0.5rem"
              >
                <div class="title-info">
                  CMND/CCCD
                </div>
                <div class="content-info">
                  {{ memberInfo.cmnd || 'Không xác định' }}
                </div>
              </div>
            </div>
            <div
              class="d-flex flex-wrap prf-col"
              style="margin: 0 -0.5rem"
            >
              <div
                class="one-item w-50"
                style="padding: 0 0.5rem"
              >
                <div class="title-info">
                  Số tài khoản ngân hàng
                </div>
                <div class="content-info">
                  {{ memberInfo.bank_account_number || 'Không xác định' }}
                </div>
              </div>
              <div
                class="one-item w-50"
                style="padding: 0 0.5rem"
              >
                <div class="title-info">
                  Ngân hàng
                </div>
                <div class="content-info">
                  {{ memberInfo.bank_name || 'Không xác định' }}
                </div>
              </div>
            </div>
            <div
              class="d-flex flex-wrap prf-col"
              style="margin: 0 -0.5rem"
            >
              <div
                class="one-item w-50"
                style="padding: 0 0.5rem"
              >
                <div class="title-info">
                  Mặt trước CMND/CCCD
                </div>
                <img
                  v-if="memberInfo.cmnd_front"
                  :src="memberInfo.cmnd_front"
                  alt="Mặt sau CMND/CCCD"
                  style="max-width: 100%"
                >
                <span v-else>Không xác định</span>
              </div>
              <div
                class="one-item w-50"
                style="padding: 0 0.5rem"
              >
                <div class="title-info">
                  Mặt sau CMND/CCCD
                </div>
                <img
                  v-if="memberInfo.cmnd_back"
                  :src="memberInfo.cmnd_back"
                  alt="Mặt sau CMND/CCCD"
                  style="max-width: 100%"
                >
                <span v-else>Không xác định</span>
              </div>
            </div>
            <div class="one-item">
              <div class="title-info">
                Địa chỉ
              </div>
              <div class="content-info">
                {{ memberInfo.customer[0].address || 'Không xác định' }}
              </div>
            </div>
            <div
              v-if="memberInfo.is_active === 1"
              class="d-flex"
              style="gap: 16px"
            >
              <!-- <div class="btn none-bg" @click="modal_canhbao=true">Cảnh báo</div>
              <div class="btn bg-btn" @click="modal_khoa=true">Khóa tài khoản</div> -->
              <b-button
                class="w-100"
                variant="outline-warning"
                @click="modal_canhbao = true"
              >
                Cảnh báo
              </b-button>
              <b-button
                class="w-100"
                variant="danger"
                @click="modal_khoa=true"
              >
                Khóa tài khoản
              </b-button>
            </div>
            <div
              v-else
              class="d-flex"
              style="gap: 16px"
            >
              <b-button
                class="w-100"
                variant="outline-dark"
                @click="memberInfoShow = false"
              >
                Không kích hoạt
              </b-button>
              <b-button
                class="w-100"
                variant="success"
                @click="onChangeMemberStatus(1)"
              >
                Kích hoạt
              </b-button>
            </div>
          </div>
        </b-card-text>
      </b-modal>

      <!-- canh bao modal -->
      <b-modal
        id="modal-canhbao"
        v-model="modal_canhbao"
        title="Cảnh báo"
        ok-only
        no-close-on-backdrop
        hide-footer
      >
        <b-card-text>
          <div class="w-100 modal-profile">
            <b-form-group>
              <div class="img-avata">
                <feather-icon
                  icon="AlertCircleIcon"
                  size="64"
                  class="text-warning"
                />
              </div>
            </b-form-group>
            <b-form-group>
              <div class="title-big-modal">
                Gởi thông báo & Tạm dừng tài khoản CTV
              </div>
            </b-form-group>
            <b-form-group
              :label="`${$t('Lý do khóa tài khoản:')}`"
              label-cols-md="12"
              class="inline-time-group"
            >
              <div class="ctrl-radio">
                <b-form-radio
                  v-model="SelectedKTK"
                  name="some-radios"
                  value="Có hành vi gian lận"
                >
                  Có hành vi gian lận
                </b-form-radio>
                <b-form-radio
                  v-model="SelectedKTK"
                  name="some-radios"
                  value="Tài khoản ảo"
                >
                  Tài khoản ảo
                </b-form-radio>
                <b-form-radio
                  v-model="SelectedKTK"
                  name="some-radios"
                  value="Có thái độ không tốt với khách hàng"
                >
                  Có thái độ không tốt với khách hàng
                </b-form-radio>
                <b-form-radio
                  v-model="SelectedKTK"
                  name="some-radios"
                  value="Nhiều đánh giá không tốt từ khách hàng"
                >
                  Nhiều đánh giá không tốt từ khách hàng
                </b-form-radio>
                <b-form-radio
                  v-model="SelectedKTK"
                  name="some-radios"
                  value="Lý do khác"
                >
                  Lý do khác
                </b-form-radio>
                <b-form-input
                  v-if="SelectedKTK === 'Lý do khác'"
                  v-model="SelectedKTKValue"
                  placeholder="Nhập lý do"
                />
              </div>
            </b-form-group>
            <b-form-group
              :label="`${$t('Hành động')}`"
              label-cols-md="12"
              class="inline-time-group"
            >
              <div class="ctrl-radio">
                <b-form-radio
                  v-model="SelectedNN"
                  name="some-radiosnn"
                  value="0"
                >
                  Nhắc nhở
                </b-form-radio>
                <b-form-radio
                  v-model="SelectedNN"
                  name="some-radiosnn"
                  value="3"
                >
                  Tạm dừng 3 ngày
                </b-form-radio>
                <b-form-radio
                  v-model="SelectedNN"
                  name="some-radiosnn"
                  value="7"
                >
                  Tạm dừng 1 tuần
                </b-form-radio>
                <b-form-radio
                  v-model="SelectedNN"
                  name="some-radiosnn"
                  value="14"
                >
                  Tạm dừng 2 tuần
                </b-form-radio>
                <b-form-radio
                  v-model="SelectedNN"
                  name="some-radiosnn"
                  value="30"
                >
                  Tạm dừng 1 tháng
                </b-form-radio>
                <b-form-radio
                  v-model="SelectedNN"
                  name="some-radiosnn"
                  value="90"
                >
                  Tạm dừng 3 tháng
                </b-form-radio>
              </div>
            </b-form-group>
            <div
              class="d-flex mt-2"
              style="gap: 16px"
            >
              <!-- <div class="btn" @click="modal_canhbao=false">Hủy</div>
              <div class="btn none-bg" @click="modal_canhbao=false">Cảnh báo</div> -->
              <b-button
                class="w-100"
                variant="outline-dark"
                @click="modal_canhbao = false"
              >
                Hủy
              </b-button>
              <b-button
                class="w-100"
                variant="warning"
                @click="onChangeMemberStatus(2)"
              >
                Cảnh báo
              </b-button>
            </div>
          </div>
        </b-card-text>
      </b-modal>

      <!-- khoa modal -->
      <b-modal
        id="modal-khoa"
        v-model="modal_khoa"
        title="Khóa tài khoản"
        ok-only
        no-close-on-backdrop
        hide-footer
      >
        <b-card-text>
          <div class="w-100 modal-profile">
            <b-form-group>
              <div class="img-avata">
                <feather-icon
                  icon="AlertTriangleIcon"
                  size="64"
                  class="text-danger"
                />
              </div>
            </b-form-group>
            <b-form-group>
              <div class="title-big-modal">
                Gởi thông báo & Khóa tài khoản vĩnh viễn CTV
              </div>
            </b-form-group>
            <b-form-group
              :label="`${$t('Lý do khóa tài khoản:')}`"
              label-cols-md="12"
              class="inline-time-group"
            >
              <div class="ctrl-radio">
                <b-form-radio
                  v-model="SelectedKTK"
                  name="some-radios"
                  value="Có hành vi gian lận"
                >
                  Có hành vi gian lận
                </b-form-radio>
                <b-form-radio
                  v-model="SelectedKTK"
                  name="some-radios"
                  value="Tài khoản ảo"
                >
                  Tài khoản ảo
                </b-form-radio>
                <b-form-radio
                  v-model="SelectedKTK"
                  name="some-radios"
                  value="Có thái độ không tốt với khách hàng"
                >
                  Có thái độ không tốt với khách hàng
                </b-form-radio>
                <b-form-radio
                  v-model="SelectedKTK"
                  name="some-radios"
                  value="Nhiều đánh giá không tốt từ khách hàng"
                >
                  Nhiều đánh giá không tốt từ khách hàng
                </b-form-radio>
                <b-form-radio
                  v-model="SelectedKTK"
                  name="some-radios"
                  value="Lý do khác"
                >
                  Lý do khác
                </b-form-radio>
                <b-form-input
                  v-if="SelectedKTK === 'Lý do khác'"
                  v-model="SelectedKTKValue"
                  placeholder="Nhập lý do"
                />
              </div>
            </b-form-group>
            <div
              class="d-flex mt-2"
              style="gap: 16px"
            >
              <!-- <div class="btn" @click="modal_khoa=false">Hủy</div>
              <div class="btn bg-btn" @click="modal_khoa=false">Khóa TK</div> -->
              <b-button
                class="w-100"
                variant="outline-dark"
                @click="modal_khoa = false"
              >
                Hủy
              </b-button>
              <b-button
                class="w-100"
                variant="danger"
                @click="onChangeMemberStatus(3)"
              >
                Khóa TK
              </b-button>
            </div>
          </div>
        </b-card-text>
      </b-modal>
    </b-card>
  </AffiliateLayout>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BCard,
  BCardBody,
  // BCardHeader,
  BButton,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BCardText,
  BBadge,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import { required } from '@validations'
import Vue from 'vue'
import flatPickr from 'vue-flatpickr-component'
import MixinAffiliate from '../mixin'
import AffiliateLayout from '../layouts/AffiliateLayout.vue'

Vue.mixin(MixinAffiliate)

export default {
  components: {
    AffiliateLayout,
    ValidationProvider,
    // ValidationObserver,
    BCard,
    BCardBody,
    // BCardHeader,
    BButton,
    BPagination,
    BFormSelect,
    BFormGroup,
    vSelect,
    VueGoodTable,
    BFormInput,
    flatPickr,
    BFormRadio,
    BCardText,
    BBadge,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      unitPrice: MixinAffiliate.unitFormatOriginal,
      modal_canhbao: false,
      modal_khoa: false,
      required,
      nameGroup: '',
      SelectedGT: 'A',
      SelectedKTK: 'Có hành vi gian lận',
      SelectedKTKValue: '',
      SelectedNN: 0,
      activeTab: 'all',
      obCountStatus: {},
      listTileTab: [
        {
          name: 'Tất cả',
          id: 'all',
        },
        {
          name: 'Chờ duyệt',
          id: '0',
        },
        {
          name: 'Đang hoạt động',
          id: '1',
        },
        {
          name: 'Tạm dừng',
          id: '2',
        },
        {
          name: 'Dừng',
          id: '3',
        },
      ],
      model: {
        list: [],
        type: 1,
        percent_discount: 0,
        fixed_costs: 0,
        selectedHang: [],
        selectedKhac: [],
      },
      optionsHang: [
        { id: 'c', name: 'Đồng' },
        { id: 'a', name: 'Bạc' },
        { id: 'b', name: 'Vàng' },
      ],
      optionsKhac: [
        { id: 'c', name: 'danh muc 1' },
        { id: 'a', name: 'danh muc 2' },
        { id: 'b', name: 'danh muc 3' },
        { id: 'd', name: 'danh muc 4' },
        { id: 'e', name: 'danh muc 5' },
      ],
      categoryOptions: [],
      fields: [
        {
          field: 'id_user', label: 'UserID', tdClass: 'text-left', thClass: 'text-left', sortable: false,
        },
        {
          field: 'name', label: 'Tên đầy đủ', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
        {
          field: 'phone', label: 'SĐT', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
        {
          field: 'email', label: 'Email', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
        {
          field: 'status', label: 'Status', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
      ],
      listItems: [],
      pageLength: 10,
      currentPage: 1,
      totalRows: 20,
      isEdit: false,
      memberInfo: null,
      memberInfoShow: false,
      filterSelected: null,
      filterList: [
        {
          value: 'tuan',
          label: 'Tuần',
        },
        {
          value: 'thang',
          label: 'Tháng',
        },
        {
          value: 'quy',
          label: 'Quý',
        },
        {
          value: 'nam',
          label: 'Năm',
        },
        {
          value: 'su-kien-hot',
          label: 'Sự kiện hot',
        },
      ],
    }
  },
  watch: {
    activeTab(val) {
      this.loadCustomerList(val)
    },
  },
  created() {
    this.loadCategory()
    // this.loadCommission()
    this.loadCustomerList()
    this.countStatus()
  },
  methods: {
    toastFunc(
      options = {
        title: '',
        content: '',
        icon: 'CheckCircleIcon',
        variant: '',
        position: 'top-right',
      },
    ) {
      this.$toast({
        component: ToastificationContent,
        position: options.positon,
        props: {
          title: options.title,
          icon: options.icon,
          variant: options.variant,
          text: options.content,
        },
      })
    },
    // handleShowEdit(data) {
    //   this.isEdit = true
    //   const ids = data.commission_product.map(x => x.category_id)
    //   this.model = {
    //     id: data.id,
    //     fixed_costs: data.fixed_costs,
    //     percent_discount: data.percent_discount,
    //     type: data.type,
    //     list: ids,
    //   }
    // },
    // async loadCommission() {
    //   const params = {
    //     limit: this.pageLength,
    //     page: this.currentPage,
    //     type: '1',
    //     'sort[]': 'created_at,desc',
    //     site_id: JSON.parse(localStorage.getItem('siteID')).id_number,
    //   }
    //   const res = await MixinAffiliate.get('/api/v1/commissions', params)
    //   if (res.status) {
    //     this.totalRows = res.data.total
    //     this.items = res.data.items
    //   }
    // },
    async loadCategory() {
      const params = {
        limit: '1000',
        is_commission: '1',
      }
      const res = await this.mixGet('/categories', params)
      if (res.status) {
        this.categoryOptions = res.data.items
      }
    },
    async handlerDelete(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.id) {
          try {
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id_number,
            }
            const res = await MixinAffiliate.delete(`/api/v1/commission/${id}`, params)
            if (res.status) {
              this.loadCommission()
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Commission has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    async loadCustomerList(isActive = 'all') {
      const result = []
      let res
      if (isActive === 'all') {
        res = await MixinAffiliate.get('/api/v1/customers')
      } else {
        const params = {
          is_active: isActive,
        }
        res = await MixinAffiliate.get('/api/v1/customers', params)
      }
      if (res.status) {
        res.data.items.map(item => {
          const info = item.customer[0]
          result.push({
            ...info,
            name: info.fullname,
            idUser: info.id,
            status: info.is_active,
            id: item.id,
            is_active: item.is_active,
          })
          return true
        })
      }
      this.listItems = result
    },
    async onShowDetail(id) {
      const res = await MixinAffiliate.get(`/api/v1/customer/${id}`)

      if (res) {
        this.memberInfoShow = true
        this.memberInfo = res.data
      }
    },
    async onChangeMemberStatus(status) {
      let params = {
        is_active: status,
      }
      const reasonKTK = this.SelectedKTK === 'Lý do khác' ? this.SelectedKTKValue : this.SelectedKTK

      if (status === 2) {
        const today = new Date()
        const priorDate = new Date(new Date().setDate(today.getDate() + Number(this.SelectedNN)))
        const dayBlock = priorDate.toISOString()

        if (this.SelectedNN === 0) {
          params = Object.assign(params, { reason: reasonKTK })
        } else {
          params = Object.assign(params, { reason: reasonKTK, is_unlock: dayBlock })
        }
      }
      if (status === 3) {
        params = Object.assign(params, { reason: reasonKTK })
      }

      const res = await MixinAffiliate.put(`/api/v1/customer/${this.memberInfo.id}`, params)

      switch (status) {
        case 0:
          if (res.status) {
            const toast = {
              title: 'Kích hoạt tài khoản thành công!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            }
            this.toastFunc(toast)

            this.memberInfoShow = false
          } else {
            const toast = {
              title: 'Kích hoạt tài khoản không thành công. Vui lòng thử lại!',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
            }
            this.toastFunc(toast)
          }
          break

        case 1:
          if (res.status) {
            const toast = {
              title: 'Kích hoạt tài khoản thành công!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            }
            this.toastFunc(toast)

            this.memberInfoShow = false
          } else {
            const toast = {
              title: 'Kích hoạt tài khoản không thành công. Vui lòng thử lại!',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
            }
            this.toastFunc(toast)
          }
          break

        case 2:
          if (res.status) {
            const toast = {
              title: 'Cảnh báo tài khoản thành công!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            }
            this.toastFunc(toast)

            this.modal_canhbao = false
          } else {
            const toast = {
              title: 'Cảnh báo tài khoản không thành công. Vui lòng thử lại!',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
            }
            this.toastFunc(toast)
          }
          break

        case 3:
          if (res.status) {
            const toast = {
              title: 'Khóa tài khoản thành công!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            }
            this.toastFunc(toast)

            this.modal_khoa = false
          } else {
            const toast = {
              title: 'Khóa tài khoản không thành công. Vui lòng thử lại!',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
            }
            this.toastFunc(toast)
          }
          break

        default:
          break
      }

      this.SelectedKTK = 'Có hành vi gian lận'
      this.SelectedKTKValue = ''
      this.SelectedNN = 0
      this.loadCustomerList()
    },
    async countStatus() {
      const res = await MixinAffiliate.get('/api/v1/customer_count_is_active', {}, { auth: true })
      if (res.status) {
        res.data.map(val => {
          const temp = {}
          temp[`status${val.status}`] = val
          this.obCountStatus = { ...this.obCountStatus, ...temp }
          return true
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.custom-good-table.commission {
  .flex-wrap {
    display: flex;
    gap: 1rem 0;
    flex-direction: row;
    flex-wrap: wrap;
  }
  strong {
    cursor: pointer;
  }
}
.custom-good-table-ctrl-border{
  .vgt-table{
    border: 1px solid #fff !important;
  }
  td{
    padding: 0.75rem !important;

    &:first-child {
      padding-left: 1.5rem !important;
    }
    &:last-child {
      padding-right: 1.5rem !important;
    }
  }
  thead{
    background-color: #f3f2f7
  }
}
</style>
<style lang="scss">
.member-block{
  .list-title-tab{
    display: flex;
    align-items: center;
    .one-item{
      padding: 6px 16px;
      cursor: pointer;
      &.active{
        border-bottom: 2px solid #7367f0;
        color: #7367f0;
      }
    }
  }
}
.list-btn-block{
  display: flex;
  align-items: center;
  gap: 16px;
  &.btn-block{
    justify-content: space-between;
    margin-top: 24px;
    padding-bottom: 8px;
    .btn{
      width: calc(50% -  8px);
    }
  }
  .form-group{
    margin-bottom: 0;
  }
  .btn{
    background-color: #7367f0;
    color: #fff;
    border: 1px solid #7367f0;
    &:hover{
      background-color: #fff;
      color: #7367f0;
    }
    &.none-bg{
      background-color: #fff;
      color: #ECA124;
      border: 1px solid #ECA124;

      &:hover{
        background-color: #ECA124;
        color: #fff;
      }
    }
    &.bg-btn{
      background-color: #F75E5E;
      border: 1px solid #F75E5E;
      &:hover{
        color: #fff;
        opacity: 0.8;
      }
    }
  }
}
.ctrl-radio{
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.img-avata{
  background-color: #f0f0f0;
  padding: 16px;
  text-align: center;
  img{
    width: 140px;
    height: 140px;
    border-radius: 50%;
    object-fit: cover;
  }
}
.title-big-modal{
  font-size: 20px;
  color: #7367f0;
  font-weight: bold;
}
.modal-profile{
  .one-item{
    margin-bottom: 16px;
    .title-info{
      font-size: 13px;
      color: #3E444B;
      margin-bottom: 4px;
    }
    .content-info{
      font-size: 16px;
      color: #3E444B;
      font-weight: bold;
    }
  }
}
#modal-canhbao{
  .modal-header{
    background-color: #ECA124;
    .modal-title{
      color: #fff;
    }
  }
}
#modal-khoa{
  .modal-header{
    background-color: #F75E5E;
    .modal-title{
      color: #fff;
    }
  }
}
</style>
